import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-ads-component",
  templateUrl: "./tab-enable-ads-component.component.html",
  styleUrls: ["./tab-enable-ads-component.component.scss"],
})
export class TabEnableAdsComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableAds= false;
  tabList = {};
  sortedTabList = [];
  page: Array<any> = [];
  data: any;
  off_canvas_key: string = "tab";
  isAndroid: boolean;
  component_id: string;
  field_id: string;
  offcanvasColors = offcanvasColors;
  currentTabSubscription: any;
  newTab = false;


  ngOnInit() {
    this.field_id='tab_enable_ads';
    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab && !this.tab.ads) {
      // set the default.
      this.enableAds =  false;
    }
    else if(this.tab.ads && this.tab.ads.allow){
      if(this.tab.ads.allow == 1){
        this.enableAds = true;
      }
      else {
        this.enableAds = false;
      }
  }
  }

  toggleAds(event) {
    this.enableAds = event;
    const ads: any = {};
    if (this.enableAds) {
      ads.allow = 1;
      this.tab.ads = ads;
    } else {
      ads.allow = 0;
      this.tab.ads = ads;
    }

    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data['target'] ='tabSetting'


    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
