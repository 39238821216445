<app-image-uploader
  [data]="imageConfig"
  [bg]="bgimage"
  (images)="buttonImage($event)"
></app-image-uploader>
<ng-container *ngIf="config.isDark">
  <div class="card m-0 bg-transparent">
    <div class="card-body pt-0" [class.pb-0]="toggleDarkImage">
      <div class="form-check d-flex align-items-center justify-content-between p-0">
        <label class="offcanvas-header-text w-100 m-0" [for]="button?.id + 'dark'">
          Dark Mode Image
        </label>
        <input
          class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
          type="checkbox"
          [checked]="toggleDarkImage"
          (change)="enableDarkImageMode($event)"
          style="width: 18px; height: 18px;"
          [id]="button?.id + 'dark'"
        />
      </div>
    </div>
  </div>
  <app-image-uploader
    *ngIf="toggleDarkImage"
    [data]="imageDarkConfig"
    [bg]="bgimage"
    (images)="buttonImageDark($event)"
  ></app-image-uploader>
</ng-container>

<div class="card m-0 bg-transparent" *ngIf="config?.setcolor">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-9 d-flex align-items-center">
        <h6 class="m-0 offcanvas-header-text d-flex align-items-center">
          Background Colors
        </h6>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <button *ngIf="!this.enableBackgraund" type="button" class="btn btn-primary" (click)="toggle(true)">SET</button>
        <span *ngIf="this.enableBackgraund" class="offcanvas-danger-text enablePointer" (click)="toggle(false)">
          Remove
        </span>
      </div>
    </div>
    <div *ngIf="this.enableBackgraund" class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          Start Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('start',$event, true)"
            [value]="start_color"
           />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="start_color"
              [(colorPicker)]="start_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('start',$event, false)"

            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          End Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('end',$event,true)"
            [value]="end_color"
            />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="end_color"
              [(colorPicker)]="end_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('end',$event, false)"
              >
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

