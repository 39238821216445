import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TabNames, c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-web-view-component",
  templateUrl: "./tab-web-view-component.component.html",
  styleUrls: ["./tab-web-view-component.component.scss"],
})
export class TabWebViewComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private formBuilder: UntypedFormBuilder
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  newTab = false;
  wv_url = "";
  currentTabSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";

  off_canvas_key: string = "tab";
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  urlForm: UntypedFormGroup;
  regURL =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  submitted = false;

  ngOnInit() {
    this.field_id = "tab_web_view_url";
    this.urlForm = this.formBuilder.group({
      url: new UntypedFormControl("", [Validators.pattern(this.regURL)]),
    });

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.submitted = false;
      if (this.tab.web_view && this.tab.web_view.wv_url) {
        this.urlForm = this.formBuilder.group({
          url: new UntypedFormControl(this.tab.web_view.wv_url || "", [
            Validators.pattern(this.regURL),
          ]),
        });
      }
    }
  }

  get f() {
    return this.urlForm.controls;
  }

  urlChange(value) {
    this.submitted = true;
    if (this.urlForm.valid) {
      const webView: any = {};
      webView.wv_url = this.urlForm.value.url;
      this.tab.web_view = webView;
      this.updateTabData();
    }
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data["target"] = "tabSetting";

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
