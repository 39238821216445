<div class="not-allowed">
  <div *ngIf="isAndroid">
    <div
      class="card m-0 rounded bg-transparent"
      [ngClass]="fontSmaller ? 'p-0' : 'p-0'"
      [ngClass]="shadow ? 'shadow z-3' : ''"
      [style]="
        'border:' +
        outlined_border +
        '; border-color: ' +
        border_color +
        '!important'
      "
    >
      <img
        [src]="emptyImage"
        class="card-img"
        alt="empty"
        [ngStyle]="{ height: cellHeight }"
      />
      <div class="card-img-overlay p-0">
        <div
          class="btn-group w-100"
          role="group"
          aria-label="Basic example"
          [ngStyle]="{ height: cellHeight }"
          [ngClass]="no_cells == 2 && mobileView ? 'm-75 m-auto' : ''"
        >
          <ng-container
            *ngFor="let option of options; index as index; let last = last"
          >
            <a
              class="btn btn-outline-primary border-1 d-block text-truncate"
              aria-current="page"
              [ngClass]="optionPadding"
              [ngStyle]="
                activeOption[option.id] ? optionSelectedBgColor : optionBgColor
              "
              [class.btn-rounded]="index == 0 || last"
              [style]="
                'border:' +
                outlined_border +
                '; border-color: ' +
                border_color +
                '!important;' +
                'pointer-events: none;' +
                'flex: 1;'
              "
            >
              <div
                class="d-flex align-items-center justify-content-center w-100 h-100"
              >
                <i
                  *ngIf="activeOption[option.id]"
                  [ngStyle]="
                    activeOption[option.id] ? leadingIconColor : optionTextColor
                  "
                  class="float-none w-auto m-0 px-1"
                  [ngStyle]="
                    activeOption[option.id] ? leadingIconColor : optionTextColor
                  "
                  [class]="
                    builderService?.getIcon('done').type == 'mir'
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                  >done</i
                >
                <i
                  *ngIf="
                    (option.icon && !activeOption[option.id] && option.label) ||
                    (option.icon && !option.label)
                  "
                  class="float-none w-auto m-0 px-1"
                  [ngStyle]="
                    activeOption[option.id] ? leadingIconColor : optionTextColor
                  "
                  [class]="
                    builderService?.getIcon(option?.icon).type == 'mir'
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                  >{{ builderService?.getIcon(option?.icon).id }}</i
                >
                <p
                  class="m-0 p-0 text-truncate"
                  [ngStyle]="
                    activeOption[option.id] ? labelColor : optionTextColor
                  "
                >
                  {{ option.label }}
                </p>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- ********* iOS Active and Default **************** -->

  <div *ngIf="!isAndroid">
    <div
      class="card m-0 rounded"
      [ngClass]="fontSmaller ? 'p-0' : 'p-0'"
      [ngClass]="shadow ? 'shadow z-3' : ''"
      [style]="
        'border:' +
        outlined_border +
        '; border-color: ' +
        border_color +
        '!important'
      "
    >
      <img
        [src]="emptyImage"
        class="card-img"
        alt="empty"
        [ngStyle]="{ height: cellHeight }"
      />
      <div class="card-img-overlay p-0">
        <div
          class="row m-0 p-1 w-100 h-100"
          [ngStyle]="{
            'border-radius': '0.5rem !important',
            'flex-wrap': 'nowrap'
          }"
        >
          <ng-container *ngFor="let option of options; index as index; let last=last;">
            <div
              class="col d-flex align-items-center justify-content-center text-truncate"
              [class.act]="activeOption[option.id]"
              [ngStyle]="activeOption[option.id] ? optionBgColor : null"
            >
              <div class="d-flex align-items-center justify-content-center w-100 h-100">
                <p *ngIf="option.label" class="m-0 p-0 px-1 text-truncate" [ngStyle]="activeOption[option.id]? labelColor: optionTextColor" >
                  {{ option.label }}
                </p>
                <i *ngIf="!option.label" class="float-none w-auto m-0 px-1" [ngStyle]="activeOption[option.id]? leadingIconColor: optionTextColor"  [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
              </div>
            </div>
            <span *ngIf="!last" class="border-end border-1 p-0" style="width: fit-content; height: 80%; margin: auto;" [ngStyle]="{'border-color' : border_color}"></span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
