<f-flow fDraggable (fLoaded)="onLoaded()" style="height: 80vh">
  <f-canvas fZoom>
    <f-connection *ngFor="let link of tabLinks" [fBehavior]="eConnectionBehaviour.FIXED" [fReassignDisabled]="true" [fOutputId]="link.out" [fInputId]="link.inputId" fType="segment">
      <svg viewBox="0 0 10 10" fMarker [type]="eMarkerType.START" [height]="10" [width]="10" [refX]="5" [refY]="5">
        <circle cx="5" cy="5" r="2" stroke="none" fill="var(--connection-gradient-1)"></circle>
      </svg>
      <svg viewBox="0 0 700 700" fMarker [type]="eMarkerType.END" [height]="5" [width]="5" [refX]="4" [refY]="2.5">
        <path fill="var(--connection-gradient-2)" d="M0,0L700,350L0,700L150,350z"/>
      </svg>
    </f-connection>
    <div
      *ngFor="let tab of sortedTabList"
      class="node-with-card"
      [fNodePosition]="tab && tab.position ? tab?.position : null"
      fNode
      fDragHandle
      fNodeInput [fInputMultiple]="true" [fInputId]="tab?.id" fInputConnectableSide="auto"
    >
      <div class=" card mb-0 p-0 h-100 pe-none">
        <div class="card-body p-0 rounded-3 h-100">
        <!-- <app-mobile-view-tab *ngIf="tab" [inputTab]="tab" [fontSmaller]="true"></app-mobile-view-tab> -->
        <app-mobile-view *ngIf="tab" [inputTab]="tab" [onlyMobile]="true"  [tabTheme]="true"></app-mobile-view>
        </div>
      </div>
      <div class="connectors-line">
        <!-- <div *ngIf="tab?.isInputEnabled" [fNodeInput]="tab?.isInputEnabled" [fInputId]="tab?.inputId || null" [fInputConnectableSide]="tab?.inputDir"></div> -->
        <div *ngFor="let id of tab?.outputIds" fNodeOutput [fOutputId]="id" fOutputConnectableSide="right"></div>
      </div>
    </div>
  </f-canvas>
  <f-minimap></f-minimap>
</f-flow>
<div class="position-absolute bottom-50 end-0 pe-3 d-flex flex-column">
  <button class="btn btn-light border outline-variant rounded-top-4 rounded-bottom-0" (click)="changeToAndroid()">
    <i class="material-icons-outlined">android</i>
  </button>
  <button class="btn btn-light border border-top-0 outline-variant rounded-0" (click)="changeToIOS()">
    <i class="material-icons-outlined">apple</i>
  </button>
  <button class="btn btn-light border border-top-0 border-bottom-0 outline-variant rounded-0" (click)="changeToLight()">
    <i class="material-icons-outlined">light_mode</i>
  </button>
  <button class="btn btn-light border outline-variant rounded-bottom-4 rounded-top-0" (click)="changeToDark()">
    <i class="material-icons-outlined">dark_mode</i>
  </button>
</div>

