import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, c16, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-info-component",
  templateUrl: "./tab-info-component.component.html",
  styleUrls: ["./tab-info-component.component.scss"],
})
export class TabInfoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  @Input() config: any;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  colorsComponentSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;

  off_canvas_key: string = "tab";
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  enableCustomColor = false;
  enableCustomColorIOS = false;
  sync;
  labelColor;
  labelDarkColor;
  labelColorIOS;
  labelDarkColorIOS;
  light: boolean = true;
  colors: any = {};
  color_schema: any;
  color_schema_ios: any;

  ngOnInit() {
    this.field_id = "tab_info";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data &&data.off_canvas_key &&  data.off_canvas_key === "tab" &&  data.tab &&  data.tab.id && data.tab.id == this.tab_id) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );


  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {

      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      if (this.tab.title || this.tab.title_ios) {
        this.title = this.isAndroid ? this.tab.title : this.tab.title_ios;
      }

      if (this.tab.icon) {
        this.icon = this.builderService.getIcon(this.tab.icon);
      }

      if (this.tab.icon_ios) {
        this.icon_ios = this.builderService.getIcon(this.tab.icon_ios);
      }

      if(this.tab.color_schema && this.tab.color_schema.schemes && this.tab.color_schema.schemes.light && this.tab.color_schema.schemes.light.icon && this.tab.color_schema.schemes.dark && this.tab.color_schema.schemes.dark.icon){
        this.enableCustomColor=true;
        this.labelColor= this.tab.color_schema.schemes.light.icon;
        this.labelDarkColor= this.tab.color_schema.schemes.dark.icon;
      }

      if(this.tab.color_schema_ios && this.tab.color_schema_ios.schemes && this.tab.color_schema_ios.schemes.light && this.tab.color_schema_ios.schemes.light.icon && this.tab.color_schema_ios.schemes.dark && this.tab.color_schema_ios.schemes.dark.icon){
        this.enableCustomColorIOS=true;
        this.labelColorIOS= this.tab.color_schema_ios.schemes.light.icon;
        this.labelDarkColorIOS= this.tab.color_schema_ios.schemes.dark.icon;
      }
      if(this.config.setcolor){
        this.light = this.config.colors.light;
        this.color_schema = this.light
            ? this.config.colors.color_schema.schemes.light
            : this.config.colors.color_schema.schemes.dark;
          this.color_schema_ios = this.light
            ? this.config.colors.color_schema_ios.schemes.light
            : this.config.colors.color_schema_ios.schemes.dark;
      }
    }
  }

  iconChange(event) {
    this.icon = this.builderService.getIcon(event);
    this.tab.icon = event;
    this.modalRef.hide();
    this.updateTabData();
  }
  iosIconChange(event) {
    this.icon_ios = this.builderService.getIcon(event);
    this.tab.icon_ios = event;
    this.modalRef.hide();
    this.updateTabData();
  }
  titleChange(event) {
    this.tab.title = event;
    this.tab.title_ios = event;
    this.updateTabData();
  }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  toggle(event) {
    this.enableCustomColor = event;
    /// Android Settings
    if (this.enableCustomColor) {
      this.labelColor = this.color_schema['m2BOnSurface'] ? this.color_schema['m2BOnSurface']: null;
      this.labelDarkColor = this.color_schema['m2BOnSurface'] ? this.color_schema['m2BOnSurface']: null;
      if (this.tab.color_schema) {
        const schemes = this.tab.color_schema.schemes;
        let color_light: any = schemes.light;
        color_light = {
          ...color_light,
          icon: this.labelColor,
        };
        let color_dark = schemes.dark;
        color_dark = {
          ...color_dark,
          icon: this.labelDarkColor,
        };
        this.tab.color_schema.schemes = {
          light: { ...color_light },
          dark: { ...color_dark },
        };
      } else {
        this.tab = {
          ...this.tab,
          color_schema: {
            schemes: {
              light: { icon: this.labelColor },
              dark: { icon: this.labelDarkColor },
            },
          },
        };
      }
    } else {
      delete this.tab.color_schema.schemes.light.icon;
      delete this.tab.color_schema.schemes.dark.icon;
    }
    this.updateTabData();
  }

  colorChange(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;

    if (color) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;

      let customColor = this.mdw.customColor(
        color,
        this.config.colors.color_schema.source
      );
      this.labelColor = customColor.light;
      this.labelDarkColor = customColor.dark;
      this.tab.color_schema.schemes.light.icon = customColor.light;
      this.tab.color_schema.schemes.dark.icon = customColor.dark;
    }
    this.updateTabData();
  }

  toggleIOS(event) {
    this.enableCustomColorIOS = event;
    /// Android Settings
    if (this.enableCustomColorIOS) {
      this.labelColorIOS = this.color_schema_ios['surfaceVariant'] ? this.color_schema_ios['surfaceVariant'] : null;
      this.labelDarkColorIOS = this.color_schema_ios['surfaceVariant'] ? this.color_schema_ios['surfaceVariant'] : null;
      if (this.tab.color_schema_ios) {
        const schemes = this.tab.color_schema_ios.schemes;
        let color_light: any = schemes.light;
        color_light = {
          ...color_light,
          icon: this.labelColor,
        };
        let color_dark = schemes.dark;
        color_dark = {
          ...color_dark,
          icon: this.labelDarkColor,
        };
        this.tab.color_schema_ios.schemes = {
          light: { ...color_light },
          dark: { ...color_dark },
        };
      } else {
        this.tab = {
          ...this.tab,
          color_schema_ios: {
            schemes: {
              light: { icon: this.labelColorIOS },
              dark: { icon: this.labelDarkColorIOS },
            },
          },
        };
      }
    } else {
      delete this.tab.color_schema_ios.schemes.light.icon;
      delete this.tab.color_schema_ios.schemes.dark.icon;
    }
    this.updateTabData();
  }

  colorChangeIOS(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;

    if (color) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;

      let customColor = this.mdw.customColor(
        color,
        this.config.colors.color_schema.source
      );
      this.labelColorIOS = customColor.light;
      this.labelDarkColorIOS = customColor.dark;
      this.tab.color_schema_ios.schemes.light.icon = customColor.light;
      this.tab.color_schema_ios.schemes.dark.icon = customColor.dark;
    }
    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data["target"] = "tabSetting";

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
