<div class="card m-0 bg-transparent">
  <div class="card-body">
    <h6 class="offcanvas-header-text text-start">{{config?.name}}</h6>
    <div class="card offcanvas-primary m-0 rounded-3">
      <div class="row m-0 no-gutters align-items-center">
        <div class="col-md-4">
          <img
            *ngIf="imageUrl"
            alt="Card image"
            class="card-img img-fluid"
            [src]="imageUrl"
          />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <a href="javascript: void(0);" class="card-link" (click)="openscreenselector(selectorScreen)">
              Select
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #selectorScreen let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Screen</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-products-selector
      *ngIf="config?.isProuduct"
      [mode]="'selector'"
      [single]="true"
      [selectedProducts]="selectedProductsValues"
      [className]="className"
      (selectedNotifier)="selectedProducts($event)"
      (cancelNotifier)="modalRef?.hide()"
    ></app-products-selector>
    <app-collections-selector
      *ngIf="config?.isCollection"
      [mode]="'selector'"
      [single]="true"
      [selectedCollections]="selectedCollectionsValues"
      [className]="className"
      (selectedNotifier)="selectedCollections($event)"
      (cancelNotifier)="modalRef?.hide()"
    ></app-collections-selector>
  </div>
</ng-template>
