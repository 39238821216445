<div *ngIf="1==1">
  <div class="p-0 w-100 d-flex align-items-center justify-content-center">
    <div class="card p-0 m-0 position-relative shadow-none w-100" style="max-width: 560px">
      <img [src]="emptyImage" class="card-img" alt="empty">
      <div class="card-img-overlay p-0" [ngStyle]="setButtonBg" [ngClass]="fontSmaller?'':'rounded-3'">
        <div class="position-absolute start-0 end-0" [class.h-100]="styleP0===5">

          <div *ngIf="albums && albums[0]?.en && styleP0!==5" class="card m-0 -p0 shadow-none" ngClass="{{albums[1].round}}"
            style="background-color: rgba(0,0,0,{{albums[0].opacity}}); max-width: 260px; backdrop-filter: blur({{albums[0].blur}})">
            <img [src]="emptyImageP0" alt="empty" class="card-img">
            <div class="card-img-overlay border overflow-hidden m-0 p-0"
              [ngStyle]="{'padding': fontSmaller?albums[1].padding:''}" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="part_0"></ng-template>

            </div>
          </div>

          <div *ngIf="albums && albums[1]?.en" class="card m-0 -p0 shadow-none" ngClass="{{albums[1].round}}"
            style="background-color: rgba(0,0,0,{{albums[1].opacity}}); max-width: 560px; backdrop-filter: blur({{albums[1].blur}})" [class.h-100]="styleP0===5">
            <img [src]="emptyImageP1" alt="empty" class="card-img" [class.h-100]="styleP0===5">
            <div class="card-img-overlay border overflow-hidden m-0 p-0" [ngClass]="debug? 'border-1':'border-0'">
              <ng-template [ngTemplateOutlet]="part_1"></ng-template>
            </div>
          </div>

          <div *ngIf="albums && albums[2]?.en && styleP0!==5" class="card m-0 p-0 shadow-none" ngClass="{{albums[2].round}}"
          style="background-color: rgba(0,0,0,{{albums[2].opacity}}); max-width: 560px ; backdrop-filter: blur({{albums[2].blur}})"
          [ngStyle]="setTitleBg">
          <img [src]="emptyImageP2" alt="empty" class="card-img">
          <div class=" card-img-overlay border overflow-hidden m-0 p-0" [ngClass]="debug? 'border-1':'border-0'">
            <ng-template [ngTemplateOutlet]="part_2"></ng-template>
          </div>


       </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #part_0>
  <div class="row m-0 p-0">
  </div>
</ng-template>


<ng-template #part_1>
  <!-- ********the image in center*********** -->
  <div *ngIf="styleP0===1" class="row m-0 p-5">
    <div class=" col-12 d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
        <img *ngIf="image" [src]='image' class="object-fit-cover mx-auto d-block" [style]="'width:' + widthP0">
      </div>
    </div>

  <!-- ********the image in background*********** -->
  <div *ngIf="styleP0===2" class="row m-0 p-0">
    <div class=" col-12 p-0 m-0">
      <img *ngIf="image" [src]='image' class="card-img m-0 p-0" class="object-fit-cover mx-auto d-block" [ngStyle]="{'width':widthP0}" >
      <!-- <div class="card-img-overlay p-0" [ngStyle]="setImageP1" *ngIf="image"></div> -->
    </div>
  </div>
    <!-- ********the image & text togther*********** -->
    <div *ngIf="styleP0===3"
      class="row m-0 p-0 d-flex align-items-center justify-content-center">
      <div class=" col-5  m-0" [ngStyle]="{'padding':fontSmaller?'0px':albums[0].padding}">
        <img  *ngIf="image" [src]='image' class="object-fit-cover mx-auto d-block" [ngStyle]="{'width':widthP0}" >
      </div>
      <div class=" col-7 m-0 text-wrap mb-0" [ngStyle]="{'padding':fontSmaller?'0px':albums[0].padding}">
          <p type="text"
          class="card-title p-0 m-0 w-100 shadow-none border-0"
          [ngStyle]="lableColor">
          {{label}}
        </p>
        <p class="p-0 m-0 text-wrap" [ngStyle]="sublableColor">
          {{sublable}}
        </p>
      </div>
    </div>

        <!-- ********the image in background of the card with border has margin*********** -->
  <div *ngIf="styleP0===4" class="row px-2 m-0 h-100 d-flex justify-content-center">
        <div class=" col-12 p-0 m-0" >
       <div class="card-img-overlay p-0 m-0" [ngStyle]="setImageP1" ngClass="{{albums[1].round}}" *ngIf="bgImageP1" ></div>
    </div>
  </div>

       <!-- ********the image circle*********** -->
  <div *ngIf="styleP0===5" class="row m-0 p-0">
    <div class=" col-12 d-flex align-items-center justify-content-center position-absolute top-50 start-50 translate-middle p-0 m-0 h-100" >
        <img [src]='imageOvel' class="object-fit-cover mx-auto d-block card-img p-0 m-0 h-100" style="width:80%; " >
       <div class="card-img-overlay position-absolute top-50 start-50 translate-middle m-0 p-0" style="width:{{widthP0}} ;">
        <img [src]='image' class="mx-auto d-block p-0 m-0 h-75" style="border-radius: 50%; margin-bottom: 5% !important;" *ngIf="image">
        <div *ngIf="!styleP1" class="row text-wrap -0 p-0">
          <div class=" col-12 d-flex flex-column">
            <p type="text"
              class="card-title p-0 m-0 w-100 d-flex shadow-none border-0"  [ngClass]="label_align==='center'? 'justify-content-center align-items-center':label_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'"
              [ngStyle]="lableColor">
              {{label}}
            </p>
            <p class="p-0 m-0 text-center text-wrap" [ngStyle]="sublableColor" [ngClass]="sublabel_align==='center'? 'justify-content-center align-items-center':sublabel_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
              {{sublable}}
            </p>
          </div>
        </div>
       </div>
    </div>
  </div>
</ng-template>

<ng-template #part_2>
  <div *ngIf="styleP1"  class="row text-wrap m-0 p-0">
    <div class=" col-10 offset-1  text-center p-0 mb-0">
      <p type="text"
        class="card-title p-0 m-0 w-100 d-flex shadow-none border-0" [ngClass]="label_align==='center'? 'justify-content-center align-items-center':label_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'"
        [ngStyle]="lableColor">
        {{label}}
    </p>
      <p class="p-0 m-0 text-wrap d-flex" [ngStyle]="sublableColor" [ngClass]="sublabel_align==='center'? 'justify-content-center align-items-center':sublabel_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'" >
        {{sublable}}
      </p>
    </div>
  </div>

  <div *ngIf="!styleP1" class="row text-wrap -0 p-0">
    <div class=" col-12 d-flex position-absolute top-50 start-50 translate-middle flex-column" >
      <p type="text"
        class="card-title p-0 m-0 w-100 d-flex shadow-none border-0"  [ngClass]="label_align==='center'? 'justify-content-center align-items-center':label_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'"
        [ngStyle]="lableColor">
        {{label}}
      </p>
      <p class="p-0 m-0 text-center text-wrap" [ngStyle]="sublableColor" [ngClass]="sublabel_align==='center'? 'justify-content-center align-items-center':sublabel_align==='right'?'justify-content-end align-items-end': 'justify-content-start align-items-start'">
        {{sublable}}
      </p>
    </div>
  </div>
</ng-template>
