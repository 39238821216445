import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  TabNames,
  c1,
  common_components_map,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { FooterAction, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-post-footer-component",
  templateUrl: "./tab-post-footer-component.component.html",
  styleUrls: ["./tab-post-footer-component.component.scss"],
})
export class TabPostFooterComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  @Input() config: any;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  enablePostFooter = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  likeEnabled = false;
  likeIcon: any;
  likedIcon: any;

  replyEnabled = false;
  replyIcon: any;

  viewEnabled = false;
  viewIcon: any;

  shareEnabled = false;
  shareIcon: any;
  modalRef?: BsModalRef;
  selectedKey: string;
  selectedIconListTitle: string;
  tabList = {};
  sortedTabList = [];
  data: any;
  off_canvas_key: string = "tab";
  isAndroid: boolean;
  component_id: string;
  field_id: string;
  colors: any = {};
  iconColor: string;
  iconDarkColor: any;
  colorsComponentSubscription: any;
  color_schema_ios: any;
  color_schema: any;
  light: any;
  sync = true;
  enableCustomColor = false;

  defaultedList: any = {
    like: {
      link: "like",
      icon: "mir_thumb_up",
      status: true,
    },
    like_highlight: {
      link: "like_highlight",
      icon: "mis_thumb_up",
      status: true,
    },
    reply: {
      link: "reply",
      icon: "mir_mode_comment",
      status: true,
    },
    view: {
      link: "view",
      icon: "mis_check_circle",
      status: true,
    },
    share: {
      link: "share",
      icon: "mir_east",
      status: true,
    },
  };

  ngOnInit() {
    this.field_id = "tab_post_footer";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];

          this.tabFunction();
          this.colorInitialize();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
          this.colorInitialize();
        }
      }
    );

    this.colorsComponentSubscription =
      this.mdw.colorsComponentContainer$.subscribe((data) => {
        if (
          data &&
          data.color_schema &&
          data.color_schema_ios &&
          data.light != null
        ) {
          this.colors = data;
          this.light = data.light;
          this.color_schema = this.light
            ? this.colors.color_schema.schemes.light
            : this.colors.color_schema.schemes.dark;
          this.color_schema_ios = this.light
            ? this.colors.color_schema_ios.schemes.light
            : this.colors.color_schema_ios.schemes.dark;
        }
      });
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.enablePostFooter = this.tab.footer.hidden ? false : true;
      const defaultMenu: any = Object.values(this.defaultedList);
      for (let i = 0; i < defaultMenu.length; i++) {
        const found = this.tab.footer.action.find(
          (item) => item.link === defaultMenu[i].link
        );

        if (found) {
          this.defaultedList[defaultMenu[i].link] = found;
          defaultMenu[i] = found;
        } else {
          defaultMenu[i].status = false;
          this.defaultedList[defaultMenu[i].link] = defaultMenu[i];
        }

        switch (defaultMenu[i].link) {
          case "like":
            if (defaultMenu[i].status) {
              this.likeEnabled = true;
            }
            this.likeIcon = this.builderService.getIcon(
              defaultMenu[i].icon
            );
            break;
          case "reply":
            if (defaultMenu[i].status) {
              this.replyEnabled = true;
            }

            this.replyIcon = this.builderService.getIcon(
              defaultMenu[i].icon
            );
            break;
          case "view":
            if (defaultMenu[i].status) {
              this.viewEnabled = true;
            }

            this.viewIcon = this.builderService.getIcon(
              defaultMenu[i].icon
            );
            break;
          case "share":
            if (defaultMenu[i].status) {
              this.shareEnabled = true;
            }

            this.shareIcon = this.builderService.getIcon(
              defaultMenu[i].icon
            );
            break;
          case "like_highlight":
            this.likedIcon = this.builderService.getIcon(
              defaultMenu[i].icon
            );
            if (this.isAndroid) {
              if (
                defaultMenu[i].color_schema &&
                defaultMenu[i].color_schema.schemes &&
                defaultMenu[i].color_schema.schemes.light &&
                defaultMenu[i].color_schema.schemes.dark &&
                defaultMenu[i].color_schema.schemes.light["icon"] &&
                defaultMenu[i].color_schema.schemes.dark["icon"]
              ) {
                this.iconColor =
                  defaultMenu[i].color_schema.schemes.light.icon;
                this.iconDarkColor =
                  defaultMenu[i].color_schema.schemes.dark.icon;
                this.enableCustomColor = true;
              }
            } else {
              if (
                defaultMenu[i].color_schema_ios &&
                defaultMenu[i].color_schema_ios.schemes &&
                defaultMenu[i].color_schema_ios.schemes.light &&
                defaultMenu[i].color_schema_ios.schemes.dark &&
                defaultMenu[i].color_schema_ios.schemes.light[
                  "icon"
                ] &&
                defaultMenu[i].color_schema_ios.schemes.dark["icon"]
              ) {
                this.iconColor =
                  defaultMenu[i].color_schema_ios.schemes.light.icon;
                this.iconDarkColor =
                  defaultMenu[i].color_schema_ios.schemes.dark.icon;
                this.enableCustomColor = true;
              }
            }
            break;
        }
      }
    }
  }

  toggleFooter(event) {
    this.enablePostFooter = event;
    if (this.enablePostFooter) {
      this.tab.footer.hidden = 0;
    } else {
      this.tab.footer.hidden = 1;
    }

    this.updateTabData();
  }

  enableCheckbox(event, item) {
    switch (item) {
      case "like":
        this.likeEnabled = event.target.checked;
        this.defaultedList[item].status = event.target.checked;
        this.defaultedList["like_highlight"].status = event.target.checked;
        break;
      case "reply":
        this.replyEnabled = event.target.checked;
        this.defaultedList[item].status = event.target.checked;
        break;
      case "view":
        this.viewEnabled = event.target.checked;
        this.defaultedList[item].status = event.target.checked;
        break;
      case "share":
        this.shareEnabled = event.target.checked;
        this.defaultedList[item].status = event.target.checked;
        break;
    }
    const menu: any = Object.values(this.defaultedList);
    this.tab.footer.action = menu.filter((item) => item.status);
    this.updateTabData();
  }

  colorChange(value, manual) {
    let color = null;
    color =
      manual == true
        ? this.mdw.isValidHexaCode(value.target.value)
          ? value.target.value
          : null
        : value;
    if (color) {
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      const colors = this.isAndroid
        ? this.colors.color_schema
        : this.colors.color_schema_ios;
      let customColor = this.mdw.customColor(color, colors.source);
      this.iconColor = customColor.light;
      this.iconDarkColor = customColor.dark;
      const action = [];
      this.tab.footer.action.forEach((item) => {
        if (item.link === "like_highlight") {
          const newLikedItem: FooterAction = item;
          if (this.isAndroid || this.sync) {
            newLikedItem.color_schema = {
              schemes: {
                light: { icon: this.iconColor },
                dark: { icon: this.iconDarkColor },
              },
            };
          }
          if (!this.isAndroid || this.sync) {
            newLikedItem.color_schema_ios = {
              schemes: {
                light: { icon: this.iconColor },
                dark: { icon: this.iconDarkColor },
              },
            };
          }
          action.push(newLikedItem);
        } else {
          action.push(item);
        }
      });

      this.updateTabData();
    }
  }

  toggleColor(event) {
    this.enableCustomColor = event;
    const action = [];
    this.tab.footer.action.forEach((item) => {
      if (item.link === "like_highlight") {
        const newLikedItem: FooterAction = item;
        if (event) {
          if (this.isAndroid || this.sync) {
            this.iconColor = this.colors.color_schema.schemes.light.error;
            this.iconDarkColor = this.colors.color_schema.schemes.dark.error;
            newLikedItem.color_schema = {
              schemes: {
                light: { icon: this.iconColor },
                dark: { icon: this.iconDarkColor },
              },
            };
          }
          if (!this.isAndroid || this.sync) {
            this.iconColor = this.colors.color_schema_ios.schemes.light.error;
            this.iconDarkColor =
              this.colors.color_schema_ios.schemes.dark.error;
            newLikedItem.color_schema_ios = {
              schemes: {
                light: { icon: this.iconColor },
                dark: { icon: this.iconDarkColor },
              },
            };
          }
        } else {
          if (this.isAndroid || this.sync) {
            delete newLikedItem.color_schema;
          }
          if (!this.isAndroid || this.sync) {
            delete newLikedItem.color_schema_ios;
          }
        }
        action.push(newLikedItem);
      } else {
        action.push(item);
      }
    });
    this.updateTabData();
  }

  changeIcon(value) {
    this.defaultedList[this.selectedKey].icon = value;
    const menu: any = Object.values(this.defaultedList);
    switch (this.selectedKey) {
      case "like":
        this.likeIcon = this.builderService.getIcon(value);
        break;
      case "reply":
        this.replyIcon = this.builderService.getIcon(value);
        break;
      case "view":
        this.viewIcon = this.builderService.getIcon(value);
        break;
      case "share":
        this.shareIcon = this.builderService.getIcon(value);
        break;
      case "like_highlight":
        this.likedIcon = this.builderService.getIcon(value);
        break;
    }

    this.tab.footer.action = menu;
    this.selectedKey = undefined;
    this.selectedIconListTitle = undefined;
    this.updateTabData();
    this.modalRef.hide();
  }

  openSelector(exlargeModal: any, key) {
    this.selectedKey = key;
    switch (this.selectedKey) {
      case "like":
        this.selectedIconListTitle = "Post Like";
        break;
      case "reply":
        this.selectedIconListTitle = "Post Reply";
        break;
      case "view":
        this.selectedIconListTitle = "Post View";
        break;
      case "share":
        this.selectedIconListTitle = "Post Share";
        break;
      case "like_highlight":
        this.selectedIconListTitle = "Post Liked";
        break;
    }
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data['target'] ='tabSetting'

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  colorInitialize() {
    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = async (event) => {
      if (appHome.result) {
        this.colors["color_schema"] = appHome.result.app.tabs.color_schema;
        this.colors["color_schema_ios"] = appHome.result.app.tabs.color_schema_ios;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
