import { androidColors, iosColors } from './../../core/services/interface';
import { FontSize } from '@ckeditor/ckeditor5-font';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from 'src/app/sharedservices/builder.service';

@Component({
  selector: "app-button-separator",
  templateUrl: "./button-separator.component.html",
  styleUrls: ["./button-separator.component.scss"],
})
export class ButtonSeparatorComponent implements OnInit, OnDestroy {

  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService

  ) { }

  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() fontSmaller = false;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() data: Tab;
  @Input() colors: any;
  @Input() no_buttons = 1;

  //////////////////// what have been used //////////////
  currentMenuSubscription: any;
  updateBuilderComponentSubscription: any;
  offCanvasSubscription: any;


  helper: string = null //'Helper Text';
  error: string = null //'error';

  helper_color: string //='#8e8e93'
  error_color: string  //= '#FF0000' ;
  leading_icon: string = null// 'fas fa-heart' ;
  trailing_icon: string = null;   //= 'fas fa-check-circle';
  error_icon: string = 'fas fa-exclamation-circle';
  leading_icon_color: string
  trailing_icon_color: string


  suffix: string;
  suffix_color: string
  prefix: string;
  prefix_color: string;

  border_color: string;
  border_color_ios: string;

  bg_image: string = null;
  bg_color: string;
  start_color: string = null;
  end_color: string = null;
  image: string;
  album_button: any;
  albums: any;
  emptyImage: string;
  emptyImageP0: string;
  emptyImageP1: string;
  widthP0: string;
  label: string;
  sublabel: string;
  label_color: string;
  sublabel_color: string;
  settings: any;
  primary_color: string;
  pStyle: number;
  cellHight: string;
  labellPadding: string;
  sublabellPadding: string;
  padding: string;
  album_input_map: any;
  labelSetting: any
  sublabelSetting: any

  isAndroid: boolean;
  current_button: any;
  isActive: boolean;
  outline: string;
  label_align: string;
  sublabel_align: string;
  label_size: string;
  sublabel_size: string;
  shadow: boolean;
  outlined: boolean;


  divider: number;

  divider_color: string;

  textAlign: any = {};
  textFontsize: any = {};

  border_default_color: string = '#B8B8B8';
  androidColors: any;
  iosColors: any;


  /// colors to be common into the tab section///
  light: boolean = true;
  color_schema: any;
  color_schema_ios: any;

  ngOnInit() {

    let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;



    this.initialize(style, this.no_buttons)

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
      if (data && !data.fromButton) {
        if (data["sortedButtonList"][this.row_id]) {
          this.no_buttons = data["sortedButtonList"][this.row_id].length > 0 ? data["sortedButtonList"][this.row_id].length : 1
          this.current_button = data["currentButton"];

          // this.button = data["buttonList"][this.button.button_id]
          this.isActive = this.current_button && (this.current_button.button_id == this.button.button_id) ? true : false
          let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
         this.initialize(style, this.no_buttons)
        }

        if (data['colors']) {
          this.colors = data["colors"];
          this.light = data["light"];
          this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
          this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark
        }

        let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
        this.initialize(style, this.no_buttons)
      }

    })

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        // only for getting default button to change style for active or none active button style
        if (data && data.button) {

          this.current_button = data.button;
          this.isActive = this.current_button && (this.current_button.button_id == this.button.button_id) ? true : false
          let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
          this.initialize(style, this.no_buttons)
        }
      }
    );


    // this.updateBuilderComponentSubscription =
    //   this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
    //     if (data && data.tab) {

    //       console.log("I am in updateBuilder ");
    //       // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
    //       this.isAndroid = localStorage.getItem("mode")[0] == c1.ANDROID ? true : false;
    //       let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
    //       this.initialize(style, this.no_buttons)

    //     }
    //   });
  }
  initialize(style, no_button) {
    this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;


    if (this.colors && this.button) {
      this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
      this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark

      // Static Button Content
      this.leading_icon = this.isAndroid ? this.button.button_icon : this.button.button_icon_ios
      this.helper = this.error ? this.button.button_error : this.button.button_helper;
      this.trailing_icon = this.isAndroid ? this.error ? this.error_icon : this.isActive ? this.button.button_trailing_icon : null : this.error ? this.error_icon : this.button.button_trailing_icon_ios

      this.image = null
      this.bg_image = null
      this.start_color = null;
      this.end_color = null;

      let index = this.convertStyle(style);

      this.album_button = album_input_map[index];
      this.settings = this.isAndroid ? this.album_button.settings : this.album_button.settings_ios
      this.labelSetting = this.isAndroid ? this.album_button.label : this.album_button.label_ios;
      this.sublabelSetting = this.isAndroid ? this.album_button.sublabel : this.album_button.sublabel_ios;

      this.cellHight = this.fontSmaller ? this.settings.cellHight_s : this.settings.cellHight
      this.padding = this.fontSmaller ? this.settings.padding_s : this.no_buttons === 3 ? this.settings.padding['3'] : this.settings.padding['default']
      this.labellPadding = this.fontSmaller ? this.labelSetting.padding_s : this.no_buttons === 3 ? this.labelSetting.padding['3'] : this.labelSetting.padding['default']
      this.sublabellPadding = this.fontSmaller ? this.sublabelSetting.padding_s : this.no_buttons === 3 ? this.sublabelSetting.padding['3'] : this.sublabelSetting.padding['default']

      this.themeColors(index)
      this.helper_color = this.isAndroid ? this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'helper', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'helper', 'onSurfaceVariant', this.light)

      this.error_color = this.isAndroid ? //this.color_schema.error : this.color_schema_ios.error
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'error', 'error', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'error', 'error', this.light)


    this.textAlign = this.isAndroid?this.button.button_text_align:this.button.button_text_align_ios

    this.label_align = this.textAlign && this.textAlign.headline? this.textAlign.headline : null;
    this.sublabel_align = this.textAlign && this.textAlign.subhead ? this.textAlign.subhead: null;

    this.textFontsize =this.isAndroid?this.button.button_text_fontsize:this.button.button_text_fontsize_ios
    this.label_size = this.textFontsize && this.textFontsize.headline? this.textFontsize.headline : null;
    this.sublabel_size = this.textFontsize && this.textFontsize.subhead ? this.textFontsize.subhead: null;
    }
  }
  themeColors(style) {
    switch (style) {
      case MENU_BUTTON_STYLE.STYLE_1:
      // outlined
      this.image = null
      this.bg_image = null
      this.start_color = null;
      this.end_color = null;

      // the headline and subhead
      this.label = this.button.button_headline;
      this.sublabel =this.button.button_subhead;
      this.divider = this.button.button_divider;

      this.label_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'headline', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'headline', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'subhead', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'subhead', 'onSurfaceVariant', this.light)

    this.leading_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurfaceVariant', this.light)

    this.trailing_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurfaceVariant', this.light)
      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;

      this.border_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'outline', 'outline', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'outline', 'outline', this.light);


  //     console.log("LabelSettings.titleSize", this.labelSetting.titleSize);

  //     console.log("fontSize",  this.fontScale('headline', this.labelSetting.titleSize));

  //     let check = (this.sublabelSetting && this.sublabelSetting.titleSize) ? "first" : "second"
  // console.log("check", check);

      this.labelSetting.titleSize
     this.pStyle=1;
        break;
    }
  }
  get setButtonBg() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",

      };
    }
    else if (this.start_color && this.end_color) {

      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {


      return { background: this.bg_color };
    } else {
      return { background: "unset" };
    }
  }

  get labelColor() {
    return {
      "color": this.label_color,
      'font-size': this.fontSmaller ? '4px' : this.fontResize ? this.no_buttons == 2 ? '10px' : this.no_buttons == 3 ? '8px' : (this.labelSetting && this.labelSetting.titleSize_m) ? this.fontScale('headline', this.labelSetting.titleSize_m) : this.fontScale('headline', '12px') : this.no_buttons == 2 ? '12px' : this.no_buttons == 3 ? '10px' : (this.labelSetting && this.labelSetting.titleSize) ? this.fontScale('headline', this.labelSetting.titleSize) : this.fontScale('headline', '12px'),
    };
  }


  get sublabelColor() {
    return {
      "color": this.sublabel_color,
      'font-size': this.fontSmaller ? '4px' : this.fontResize ? this.no_buttons == 2 ? '6px' : this.no_buttons == 3 ? '4px' : (this.sublabelSetting && this.sublabelSetting.titleSize_m) ? this.fontScale('subhead', this.sublabelSetting.titleSize_m) : this.fontScale('subhead', '12px') : this.no_buttons == 2 ? '6px' : this.no_buttons == 3 ? '4px' : (this.sublabelSetting && this.sublabelSetting.titleSize) ? this.fontScale('subhead', this.sublabelSetting.titleSize) : this.fontScale('subhead', '12px'),
    };
  }




  get helperColor() {
    return {
      "color": this.helper_color ? this.helper_color : '#EBEBEB',
      'font-size': this.fontSmaller ? '2px' : this.no_buttons == 3 ? '8px' : '10px',
    };
  }

  get errorColor() {
    return {
      "color": this.error_color ? this.error_color : '#FF0000',
      'font-size': this.fontSmaller ? '2px' : this.no_buttons == 3 ? '10px' : '12px',
    };
  }


  get leadingIconColor() {
    return {
      "color": this.leading_icon_color ? this.leading_icon_color : '#676767',
      'font-size': this.fontSmaller ? '6px' : this.no_buttons == 2 ? '16px' : this.no_buttons == 3 ? '16px' : this.fontScale('label', '18px'),
    };
  }

  get traillingIconColor() {
    return {
      "color": this.trailing_icon_color ? this.trailing_icon_color : '#676767',
      'font-size': this.fontSmaller ? '4px' : this.no_buttons == 3 ? '14px' : '14px',
    };
  }

  get borderColor() {
    return {

      "border": "solid 1px " + this.borderColor,
      "height": "40px"
    };
  }



  convertStyle (style){
    //x000 : old style
    //x   : N/A mirror always 0
    //2nd : N/A   0: (some cells is divider)
    //3rd : card type
            // 0:elevated (shadow, no outline, bg),
            // 1: filled (no shadow, no outline and bg),
            // 2: outlined (no shadow, outline, bg)  //used to be boarder 1/0
            // 3: Custom
            // 4: transparent

    let cell_style = style[0];
    switch (Number(style[3])){
      case  1:
        // elevated card
        // console.log("I am elevated");
        this.outlined = false;
        this.shadow  = true;
        this.start_color = null;
        this.end_color = null;
        this.bg_color = this.isAndroid ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerLow', this.light)
       :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerLow', this.light) ;
         break;
      case 0:
        // filled card
        // console.log("I am filled");
      this.outlined = false;
      this.shadow  = false
      this.start_color = null;
      this.end_color = null;
       this.bg_color = this.isAndroid ?
       this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerHighest', this.light)
      :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHighest', this.light)
      break;

     case 2:
      // console.log("I am outlined");
        // outlined card
        this.outlined = true;
        this.shadow  = false
        this.start_color = null;
        this.end_color = null;
         this.bg_color = this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surface', this.light)
        :  this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surface', this.light)
      break;

      default:
        // console.log("I am else");
      break;


    }
    return cell_style;

}

  // getStyle(style) {
  //   return style
  // }

  fontScale(item, value) {
    let result: number;

    result = value.replace("px", "");

    // large is greater +2 and smaller is less -2
    if (this.textFontsize && this.textFontsize[item]) {

      switch (this.textFontsize[item]) {
        case 'sm':
          result = Number(result) - 2;
          return result + 'px'
          break;
        case 'md':
          return Number(result) + 'px'
          break;
        case 'lg':
          result = Number(result) + 2;
          return result + 'px'
          break;
      }
    } else {
      return value
    }
  }
  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }

    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }


}
export const album_input_map = {

  [MENU_BUTTON_STYLE?.STYLE_1]: {
    settings: { cellHight: '36px', cellHight_s: '18px', padding: { default: 'p-0 rounded-pill', 3: 'p-0 rounded-pill' }, padding_s: 'p-0 rounded-pill', },
    label: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px',titleSize_m: '10px', titleSize_s: '4px' },
    sublabel: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '8px', titleSize_m: '6px',titleSize_s: '4px' },

    settings_ios: { cellHight: '36px', cellHight_s: '18px', padding: { default: 'p-0 rounded-pill', 3: 'p-0 rounded-pill' }, padding_s: 'p-0 rounded-pill', },
    label_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '12px',titleSize_m: '10px', titleSize_s: '4px' },
    sublabel_ios: { padding: { default: 'py-0 ps-2', 3: 'py-0 ps-0' }, padding_s: 'p-0', titleSize: '10px', titleSize_m: '8px', titleSize_s: '4px' },
  },

}


















