<div class="not-allowed">
  <div *ngIf="isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0">
        <div
          class="btn-group w-100 h-100"
          role="group"
          aria-label="Basic example"
          [ngClass]="no_cells == 2 && mobileView ? 'm-75 m-auto' : ''"
        >
          <div class="d-flex align-items-center simplebar-content-wrapper">
            <ng-container *ngFor="let option of options; index as index; let last = last">
              <div
                class="px-1 py-1 d-flex align-items-center me-2"
                [ngClass]="optionborder + ' ' + style"
                [ngStyle]="optionBgColor"
                [style]="'border:' + outlined_border + '; border-color: ' + border_color + '!important;' + 'pointer-events: none;' + 'height: ' + cellHeight"
              >
                <i *ngIf="option?.icon" class="float-none m-0 ms-1" [ngStyle]="leadingIconColor"  [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
                <p class="m-0 px-2 text-truncate" [ngStyle]="optionTextColor">
                  {{ option.label }}
                </p>
                <!-- <i *ngIf="option?.icon" class="float-none m-0 me-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon('close').type=='mir'?'material-icons-outlined':'material-icons'" >close</i> -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAndroid">
    <div
      class="card m-0 rounded bg-transparent p-0 shadow-none"
    >
      <img [src]="emptyImage" class="card-img" alt="empty" [ngStyle]="{'height': fontSmaller ? '16px': '38px'}">
      <div class="card-img-overlay p-0">
        <div
          class="btn-group w-100 h-100"
          role="group"
          aria-label="Basic example"
          [ngClass]="no_cells == 2 && mobileView ? 'm-75 m-auto' : ''"
        >
          <div class="d-flex align-items-center simplebar-content-wrapper">
            <ng-container *ngFor="let option of options; index as index; let last = last">
              <div
                class="px-1 py-1 d-flex align-items-center me-2"
                [ngClass]="optionborder + ' ' + style"
                [ngStyle]="optionBgColor"
                [style]="'border:' + outlined_border + '; border-color: ' + border_color + '!important;' + 'pointer-events: none;' + 'height: ' + cellHeight"
              >
                <i *ngIf="option?.icon" class="float-none m-0 ms-1" [ngStyle]="leadingIconColor"  [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
                <p class="m-0 px-2 text-truncate" [ngStyle]="optionTextColor">
                  {{ option.label }}
                </p>
                <!-- <i *ngIf="option?.icon" class="float-none m-0 me-1" [ngStyle]="activeOption[option.id]? leadingSelectedIconColor: leadingIconColor"  [class]="builderService?.getIcon('close').type=='mir'?'material-icons-outlined':'material-icons'" >close</i> -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
