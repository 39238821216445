import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, c16, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "tab-selector-component",
  templateUrl: "./tab-selector-component.component.html",
  styleUrls: ["./tab-selector-component.component.scss"],
})
export class TabSelectorComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  @Input() config: any;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  colorsComponentSubscription: any;

  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;

  off_canvas_key: string = "tab";
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;
  isAndroid: boolean;
  data: any;
  tabList = {};
  sortedTabList = [];
  current_tab_id;
  component_id: string;
  field_id: string;
  enableCustomColor = false;
  enableCustomColorIOS = false;
  sync;
  labelColor;
  labelDarkColor;
  labelColorIOS;
  labelDarkColorIOS;
  light: boolean = true;
  colors: any = {};
  color_schema: any;
  color_schema_ios: any;
  imageUrl;
  className

  ngOnInit() {
    this.field_id = "tab_Selector";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data &&data.off_canvas_key &&  data.off_canvas_key === "tab" &&  data.tab &&  data.tab.id && data.tab.id == this.tab_id) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );


  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      this.className = this.config.category;
      if(this.tab.param && this.tab.param.image){
        this.imageUrl=this.tab.param.image;
      }
    }
  }

  openscreenselector(selectorScreen) {
    this.modalRef = this.modalService.show(selectorScreen, {
      class: "modal-xl",
    });
  }
  selectedCollections(event) {
    this.tab.param={category: event[0].category ? event[0].category : 'collection',image: event[0].image[0].url, id: event[0].id};
    this.imageUrl=event[0].image[0].url;
    this.modalRef.hide();
    this.updateTabData();
  }
  selectedProducts(event) {
    this.tab.param={category: event[0].category ? event[0].category : 'product',image: event[0].image[0].url ,id: event[0].id};
    this.imageUrl=event[0].image[0].url;
    this.modalRef.hide();
    this.updateTabData();
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data["target"] = "tabSetting";

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
