
<div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 overflow-hidden w-100 z-0" style="max-width: 260px">
  <tabset [ngClass]="{'d-none': onlyMobile!= false}" class="nav-pills nav-justified os-tabset" #tabset id="tabset">
    <tab id="android" (selectTab)="onChangeView($event)">
      <ng-template tabHeading>
        <div class="d-flex align-items-center justify-content-center">
          <i class="fab fa-android me-2" style="font-size: 16px;"></i>
          <span>Android</span>
        </div>
      </ng-template>
      <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
    </tab>
    <tab id="ios" (selectTab)="onChangeView($event)">
      <ng-template tabHeading>
        <div class="d-flex align-items-center justify-content-center">
          <i class="fab fa-apple me-2" style="font-size: 16px;"></i>
          <span>iOS</span>
        </div>
      </ng-template>
      <ng-template  [ngTemplateOutlet]="TabContent"></ng-template>
    </tab>
  </tabset>

  <ng-template *ngIf="onlyMobile == true"  [ngTemplateOutlet]="TabContent"></ng-template>
<!-- Tab Content -->
<ng-template #TabContent>
  <div class="my-2 unclickable" (click)="buildMessage()">
    <div *ngIf="theme?.view===mobileView?.a_full || theme?.view===mobileView?.i_full ">
      <div class="p-0 col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
        style="max-width: 260px">
        <div class="position-relative">
        <div class="row m-0 p-0 ">
          <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
            <div class="card p-0 m-0" style="background-color: transparent !important;">
              <img [src]="theme?.emptyImage" class="card-img " alt="empty" >
              <div class="card-img-overlay p-0 ">
                <ng-template [ngTemplateOutlet]="Components"></ng-template>
                <!-- <ng-template *ngIf="!(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Components"></ng-template>
                <ng-template *ngIf="(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Idrawer"></ng-template> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="theme?.sidemode && !theme?.tabview && isAndroid"  style="max-width: 208px" class="position-absolute start-0 top-0">
          <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
        </div>
        </div>
      </div>
    </div>
    <!-- Android Single Tab , iOS Single Tab or Component ---- All do not have bottom but ****only App bar on the top****-->
    <div *ngIf="theme?.view === mobileView?.a_main_tab ||theme?.view === mobileView?.i_main_tab ||theme?.view === mobileView?.a_component ||theme?.view === mobileView?.i_component" >
      <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}" style="max-width: 260px">
        <div class="position-relative">
          <div class="row m-0 p-0">
            <div class="col-12 p-0">
              <app-brand-bar [fontResize]="fontResize" [inputTab]="inputTab" [tabTheme]="tabTheme" class="w-100"></app-brand-bar>
            </div>
            <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
              <div class="card p-0 m-0 rounded-0" style="background-color: transparent !important;">
                <img [src]="theme?.emptyImage" class="card-img" alt="empty">
                <div class="card-img-overlay p-0">
                  <ng-template *ngIf="!(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Components"></ng-template>
                  <ng-template *ngIf="(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Idrawer"></ng-template>                </div>
              </div>
            </div>
          </div>
          <div *ngIf="theme?.sidemode && !theme?.tabview && isAndroid"  style="max-width: 208px" class="position-absolute start-0 top-0">
            <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
          </div>
          </div>
      </div>
    </div>

    <div *ngIf="theme?.view === mobileView?.a_main_many_bottom_wide || theme?.view === mobileView?.a_main_many_bottom_std || theme?.view === mobileView?.i_main_bottom_small
    || theme?.view === mobileView?.i_main_bottom_large || theme?.view === mobileView?.i_main_top_small || theme?.view === mobileView?.i_main_top_large">
      <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
        style="max-width: 260px">
        <div class="position-relative">
          <div class="row m-0 p-0">
            <div class="col-12 p-0">
              <app-brand-bar [fontResize]="fontResize" [inputTab]="inputTab"  [tabTheme]="tabTheme" class="w-100"></app-brand-bar>
            </div>
                  <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
              <div class="card p-0 m-0 rounded-0 shadow-none" style="background-color: transparent !important;">
                <img [src]="theme?.emptyImage" class="card-img" alt="empty">
                <div class="card-img-overlay p-0" >
                  <ng-template *ngIf="!(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Components"></ng-template>
                  <ng-template *ngIf="(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Idrawer"></ng-template>                </div>
              </div>
            </div>
            <div class="col-12 p-0">
              <app-navbar [fontResize]="fontResize" [tab_id]="inputTab?.id" class="w-100"></app-navbar>
            </div>
          </div>
          <div *ngIf="theme?.sidemode  && !theme?.tabview && isAndroid"  style="max-width: 208px" class="position-absolute start-0 top-0">
            <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
          </div>
         </div>
      </div>
    </div>

    <div *ngIf="theme?.view === mobileView?.a_main_many_top_wide || theme?.view === mobileView?.a_main_many_top_std">
      <div class="col-xxl-3 col-xl-3 col-md-5 col-xs-12 rounded-4 border border-5 overflow-hidden w-100" attr.style="{{'border-color: ' + outline +  '!important' }}"
        style="max-width: 260px">
        <div class="position-relative">
          <div class="row m-0 p-0">
            <div class="col-12 p-0 m-0">
              <app-brand-bar [fontResize]="fontResize" [inputTab]="inputTab" [tabTheme]="tabTheme" class="w-100"></app-brand-bar>
            </div>
            <div class="col-12 p-0 pb-0 m-0">
              <!-- <div class="col-12 p-0 pb-2">    -->
              <app-navbar [fontResize]="fontResize" [tab_id]="inputTab?.id" class="w-100"></app-navbar>
            </div>
            <div class="col-12 p-0 simplebar-content-wrapper" [ngStyle]="setTabBg">
              <div class="card p-0 m-0 rounded-0"  style="background-color: transparent !important;">
                <img [src]="theme?.emptyImage" class="card-img" alt="empty">
                <div class="card-img-overlay p-0">
                  <ng-template *ngIf="!(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Components"></ng-template>
                  <ng-template *ngIf="(theme?.sidemode && !theme?.tabview && !isAndroid)" [ngTemplateOutlet]="Idrawer"></ng-template>                </div>
              </div>
            </div>
          </div>
          <div *ngIf="theme?.sidemode  && !theme?.tabview && isAndroid"  style="max-width: 208px" class="position-absolute start-0 top-0">
            <app-navdrawerbar [fontResize]="fontResize" [tab_id]="inputTab?.id"></app-navdrawerbar>
          </div>
         </div>
      </div>
    </div>
  </div>
  <tabset [ngClass]="{'d-none': onlyMobile!= false}"  class="nav-pills nav-justified mode-tabset">
    <tab id="light" [active]="isLight" (selectTab)="onChangeMode($event)">
      <ng-template tabHeading>
        <div class="d-flex align-items-center justify-content-center">
          <i class="material-icons me-2" style="font-size: 16px;">light_mode</i>
          <span>Light</span>
        </div>
      </ng-template>
    </tab>
    <tab id="dark" [active]="!isLight" (selectTab)="onChangeMode($event)">
      <ng-template tabHeading>
        <div class="d-flex align-items-center justify-content-center">
          <i class="material-icons me-2" style="font-size: 16px;">dark_mode</i>
          <span>Dark</span>
        </div>
      </ng-template>
    </tab>
  </tabset>
</ng-template>

<!-- Components Content -->
<ng-template #Components>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENCHAT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNEL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENBOT" [fontResize]="fontResize"  [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALL_LOG" [fontResize]="fontResize"  [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VIDEOAUDIO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CONTACT" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNELLIST" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINEGROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKING" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.GROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGLIST" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOK" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALENDAR" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VALIDATE_TICKET" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.REDEEM_TICKET" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.INVITATION" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENGROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINECHANNEL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EMPTY" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SPEEDDIALER" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MYORDERS" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SETTINGS" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.PAYMENT_VIEW" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.LOGIN_VIEW" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.PRODUCT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.COLLECTION" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CATEGORY" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGPRODUCT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EVENTPRODUCT" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
  <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CART" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>

  <app-feeds *ngIf="inputTab && inputTab?.module === tabNames?.FEED" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
  <app-palette *ngIf="inputTab && inputTab?.module === tabNames?.SPLASH" [fontResize]="fontResize" [inputTab]="inputTab"></app-palette>
  <app-web-view *ngIf="inputTab && inputTab?.module === tabNames?.WEBVIEW" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
  <app-video-view *ngIf="inputTab && inputTab?.module === tabNames?.VIDEO" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
  <app-search-view *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>

 <app-menus *ngIf=" colors &&(inputData && (inputData?.module === tabNames?.MENU ||
                                 inputData?.module === tabNames?.PAGE ||
                                 inputData?.module === tabNames?.STORE ||
                                 inputData?.module === tabNames?.MARKET ||
                                 inputData?.module === tabNames?.QUERY ||
                                 inputData?.module === tabNames?.POLL) ||
                                 (splashTab && inputData?.module === tabNames?.SPLASH) ||

                    inputTab  && (inputTab?.module === tabNames?.MENU ||
                                 inputTab?.module === tabNames?.PAGE ||
                                 inputTab?.module === tabNames?.STORE ||
                                 inputTab?.module === tabNames?.MARKET ||
                                 inputTab?.module === tabNames?.QUERY ||
                                 inputTab?.module === tabNames?.POLL )||


                                 (splashTab && inputTab?.module === tabNames?.SPLASH) ) "
                                  [fontResize]="fontResize"
                                  [inputTab]="inputTab"
                                  [inputMenuId]="inputMenuId"
                                  class="h-100 d-block"
                                  [splashTab]="splashTab"
                                  [fixedTop]=true
                                  [colors]="colors"
                                  [mobileView]="true">
</app-menus>
 <app-qr-view *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontResize]="fontResize" [inputTab]="inputTab" ></app-qr-view>

<!-- ****************************sub tabs ******************************* -->
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_1" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_2" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_3" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VOICE_MESSAGE_4" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_VIDEO_CALL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_USER_PROFILE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_VIDEO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_PHOTO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_FILE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_REPLY_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MULTI_PROFILE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_MEDIA_FILE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_VIDEO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_PHOTO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SEND_LOCATION" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GROUP_SELECT_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_GALLERY" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_DELETE_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CONTACTS" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_MENU" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_TEXTM" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_FILE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_AUDIO_CALL" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_ATTACH" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_EMOJI" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_COLORED_MESSAGE_CHAT" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHANNELS_FORWARD_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SEND_LOCATION" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_SELECT_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_MESSENGER_CHAT_GROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_SELECT_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_VOICE_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_SEND_VOICE_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_TEXT_MESSAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_INFO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CREATE_CHAT_GROUP" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CREATE_CHAT_GROUP_INFO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_ATTACH" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_EMOJE" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_NOTIFICATION" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_REPLIES" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_VOICE_MESSAGE_CHECK" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_TEXT_MESSAGE_BACKGROUND" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_QR" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_INVITE_ADMIN_PARTICIPANTS" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_USER_PERMISSIONS" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SUB_CHAT_GROUP_SEND_PHOTO" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>



<app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>

 <!-- <app-qr-page-view *ngIf="inputTab && inputTab?.module === tabNames?.QR_PAGE" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view>
  <app-map-search-view *ngIf="inputTab && inputTab?.module === tabNames?.MAP_SEARCH" [fontResize]="fontResize" [inputTab]="inputTab"></app-map-search-view> -->
  <!-- <app-chat-view-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontResize]="fontResize" [inputTab]="inputTab"></app-chat-view-component> -->
</ng-template>
<ng-template #Idrawer>
  <app-idrawer></app-idrawer>
 </ng-template>
