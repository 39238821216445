import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  TabNames,
  c1,
  common_components_map,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-post-context-menu-component",
  templateUrl: "./tab-post-context-menu-component.component.html",
  styleUrls: ["./tab-post-context-menu-component.component.scss"],
})
export class TabPostContextMenuComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  currentTabSubscription: any;
  enableContextMenu = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  shareEnabled = false;
  shareIcon;

  forwardEnabled = false;
  forwardIcon;

  copyEnabled = false;
  copyIcon;

  editEnabled = false;
  editIcon;

  saveGalleryEnabled = false;
  saveGalleryIcon;

  saveMusicEnabled = false;
  saveMusicIcon;

  saveDownloadEnabled = false;
  saveDownloadIcon;

  reportEnabled = false;
  reportIcon;

  deleteEnabled = false;
  deleteIcon;

  defaultedList = {
    share: {
      link: "share",
      title: "Share",
      icon: "mir_east",
      status: true,
    },
    forward: {
      link: "forward",
      title: "Forward",
      icon: "mir_call_made",
      status: true,
    },
    copy: {
      link: "copy",
      title: "Copy",
      icon: "mir_import_contacts",
      status: true,
    },
    edit: {
      link: "edit",
      title: "Edit",
      icon: "mir_add",
      status: true,
    },
    save_to_gallery: {
      link: "save_to_gallery",
      title: "Save to Gallery",
      icon: "mir_library_books",
      status: true,
    },
    save_to_music: {
      link: "save_to_music",
      title: "Save to Music",
      icon: "mir_library_music",
      status: true,
    },
    save_to_downloads: {
      link: "save_to_downloads",
      title: "Save to Downloads",
      icon: "mir_south",
      status: true,
    },
    abuse: {
      link: "abuse",
      title: "Report abuse",
      icon: "mir_report",
      status: true,
    },
    delete: {
      link: "delete",
      title: "Delete",
      icon: "mir_warrning",
      status: true,
    },
  };
  modalRef?: BsModalRef;
  selectedKey: string;
  selectedIconListTitle: string;
  tabList = {};
  sortedTabList = [];
  data: any;
  off_canvas_key: string = "tab";
  isAndroid: boolean;
  component_id: string;
  field_id: string;
  ngOnInit() {
    this.field_id = "tab_post_menu";

    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (
          data &&
          (data["cat"] == "home" || data["cat"] == "side") &&
          data.off_canvas_key === "tab" &&
          data.current_tab &&
          this.tab_id &&
          data.current_tab.id == this.tab_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedTabList = this.data["sortedTabList"];
          this.tabList = this.data["tabList"];
          this.tab = data["tabList"][this.tab_id];
          this.tabFunction();
        }
      }
    );

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab &&
          data.tab.id == this.tab_id
        ) {
          this.TabIntialize(data);
          this.tabFunction();
        }
      }
    );
  }

  TabIntialize(data) {
    this.data = data.data;
    if (data.tab.id == this.tab_id) {
      this.tab = { ...data.tab };
      this.component_id = this.tab.id + this.field_id;
    }
    this.sortedTabList = this.data["sortedTabList"];
    this.tabList = this.data["tabList"];
    this.mode = localStorage.getItem("mode");
  }

  tabFunction() {
    if (this.tab) {
      this.isAndroid = localStorage.getItem("mode")[0] === c1.ANDROID ? true : false;
      if (this.tab.menu.length > 0) {
        this.enableContextMenu = true;
      }
      if (!this.tab.menu) {
        // set the default.
        this.tab.menu = common_components_map[this.tab.module].menu;
      }
      if (this.tab.menu.length > 0) {
        const defaultMenu = Object.values(this.defaultedList);
        for (let i = 0; i < defaultMenu.length; i++) {
          const found = this.tab.menu.find(
            (item) => item.link === defaultMenu[i].link
          );
          if (found) {
            this.defaultedList[defaultMenu[i].link] = found;
          } else {
            defaultMenu[i].status = false;
            this.defaultedList[defaultMenu[i].link] = defaultMenu[i];
          }

          // this.defaultedList[this.tab.menu[i].link].icon = this.tab.menu[i].icon;

          switch (defaultMenu[i].link) {
            case "share":
              if (defaultMenu[i].status) {
                this.shareEnabled = true;
              }
              this.shareIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "forward":
              if (defaultMenu[i].status) {
                this.forwardEnabled = true;
              }
              this.forwardIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "copy":
              if (defaultMenu[i].status) {
                this.copyEnabled = true;
              }
              this.copyIcon = this.builderService.getIcon(defaultMenu[i].icon);
              break;
            case "edit":
              if (defaultMenu[i].status) {
                this.editEnabled = true;
              }
              this.editIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "save_to_gallery":
              if (defaultMenu[i].status) {
                this.saveGalleryEnabled = true;
              }
              this.saveGalleryIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "save_to_music":
              if (defaultMenu[i].status) {
                this.saveMusicEnabled = true;
              }
              this.saveMusicIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "save_to_downloads":
              if (defaultMenu[i].status) {
                this.saveDownloadEnabled = true;
              }
              this.saveDownloadIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "abuse":
              if (defaultMenu[i].status) {
                this.reportEnabled = true;
              }
              this.reportIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
            case "delete":
              this.deleteEnabled = true;
              this.deleteIcon = this.builderService.getIcon(
                this.defaultedList[defaultMenu[i].link].icon
              );
              break;
          }
        }
      }
    }
  }

  toggleContextMenu(event) {
    this.enableContextMenu = event;
    if (this.enableContextMenu) {
      const menu = Object.values(this.defaultedList).map((res) => {
        return { ...res, status: true };
      });
      this.tab.menu = menu;
    } else {
      this.tab.menu = [];
    }

    this.updateTabData();
  }

  enableCheckbox(event, link) {
    this.defaultedList[link].status = event.target.checked;
    const menu = Object.values(this.defaultedList);

    switch (link) {
      case "share":
        this.shareEnabled = event.target.checked;

        break;
      case "forward":
        this.forwardEnabled = event.target.checked;

        break;
      case "copy":
        this.copyEnabled = event.target.checked;

        break;
      case "edit":
        this.editEnabled = event.target.checked;

        break;
      case "save_to_gallery":
        this.saveGalleryEnabled = event.target.checked;

        break;
      case "save_to_music":
        this.saveMusicEnabled = event.target.checked;

        break;
      case "save_to_downloads":
        this.saveDownloadEnabled = event.target.checked;

        break;
      case "abuse":
        this.reportEnabled = event.target.checked;

        break;
      case "delete":
        this.deleteEnabled = event.target.checked;

        break;
    }
    this.tab.menu = menu.filter((item) => item.status);
    this.updateTabData();
  }

  changeIcon(value) {
    this.defaultedList[this.selectedKey].icon = value;
    const menu = Object.values(this.defaultedList);
    switch (this.selectedKey) {
      case "share":
        this.shareIcon = this.builderService.getIcon(value);
        break;
      case "forward":
        this.forwardIcon = this.builderService.getIcon(value);
        break;
      case "copy":
        this.copyIcon = this.builderService.getIcon(value);
        break;
      case "edit":
        this.editIcon = this.builderService.getIcon(value);
        break;
      case "save_to_gallery":
        this.saveGalleryIcon = this.builderService.getIcon(value);
        break;
      case "save_to_music":
        this.saveMusicIcon = this.builderService.getIcon(value);
        break;
      case "save_to_downloads":
        this.saveDownloadIcon = this.builderService.getIcon(value);
        break;
      case "abuse":
        this.reportIcon = this.builderService.getIcon(value);
        break;
      case "delete":
        this.deleteIcon = this.builderService.getIcon(value);
        break;
    }
    this.tab.menu = menu.filter((item) => item.status);
    this.selectedKey = undefined;
    this.selectedIconListTitle = undefined;
    this.updateTabData();
    this.modalRef.hide();
  }

  openSelector(exlargeModal: any, key) {
    this.selectedKey = key;
    switch (this.selectedKey) {
      case "share":
        this.selectedIconListTitle = "Share";
        break;
      case "forward":
        this.selectedIconListTitle = "Forward";
        break;
      case "copy":
        this.selectedIconListTitle = "Copy";
        break;
      case "edit":
        this.selectedIconListTitle = "Edit";
        break;

      case "save_to_gallery":
        this.selectedIconListTitle = "Save to gallery";
        break;
      case "save_to_music":
        this.selectedIconListTitle = "Save to music";
        break;
      case "save_to_downloads":
        this.selectedIconListTitle = "Save to downloads";
        break;
      case "abuse":
        this.selectedIconListTitle = "Abuse";
        break;
      case "delete":
        this.selectedIconListTitle = "Delete";
        break;
    }
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  updateTabData() {
    //*must be there in all Tabs
    this.tabList[this.tab_id] = this.tab;

    let index = this.sortedTabList.findIndex((e) => e.id == this.tab_id);
    this.sortedTabList[index] = { ...this.tab };
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["TabList"] = this.tabList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["current_tab"] = this.tab;
    this.data['target'] ='tabSetting'


    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
